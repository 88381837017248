<template>
    <div class="">
        <div @click="open" style="margin-bottom: 10px;"  >
          请至服务商系统（
          <a>http://fws.duofangtongxin.com</a>
          
          ，登录帐号和密码与数字云一致）“资料管理-青海B主叫报备”进行主叫资料提交

        </div>

        <el-image
      style="width: 80vw; height: 80vh"
      src="https://hiszy.oss-cn-beijing.aliyuncs.com/static/imgs/szyfws.png"
      ></el-image>
        <!-- <div class="">
               
            <el-form ref="upform" size="small" :model="upform" label-width="120px" :rules="rules">
              <el-form-item label="">
                <div style="display: flex;">
                  <el-button size="small" plain class="yes-748bfd-bg" @click="add()">新增</el-button>
                    <div> &ensp; 公司名称和营业执照一致。出现未填写的主叫将可能被停用</div>
                </div>
                  
                </el-form-item>
          

<div style="display: flex;"     v-for="(item,index) in formlist " :key="item.index">
  <el-form-item label="公司名：" prop="company">
                    <el-input v-model="item.subMerchant" class="form-item-w-200" placeholder="请输入公司名" clearable />
                </el-form-item> 
                <el-form-item label="主叫号码：">
                <el-input  v-model="item.number" class="form-item-w-200"
                           placeholder="请输入号码" clearable></el-input>
              </el-form-item>


</div>
               
               
                <el-form-item label="">
                    <el-button size="small" plain class="yes-748bfd-bg" @click="upFormData('upform')">提交报备</el-button>
                </el-form-item>
            </el-form>

           
        </div>
        <div class="input-searchFrom-width-600 show-boxs">
                <div v-if="dataList.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div v-else>
                    <div v-for="(item,i) in dataList"  :key="i" class="flex-nowrap-space-around item-box-other">
                        <div>{{ item.number }}</div>
                        <div>{{ item.msg }}</div>
                    </div>
                </div>
            </div> -->
    </div>
</template>

<script>
import ctcc from '@/api/open/ctcc'
import interfacets from "@/api/open/privacy/interface";
import number from "@/api/open/privacy/number";
export default {
    components: {

    },
    data () {
        const checkIdNum = (rule, value, callback) => {
            let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
            if (!value) {
                return callback(new Error('证件号码不能为空'))
            } else if (!reg.test(value)) {
                return callback(new Error('证件号码不正确'))
            } else {
                callback()
            }
        }
        return {
          dataList: [],
          formlist: [{

            number: "",
            subMerchant: ""
          
          }
          ] ,
            upform:{
              numberList:'',
              company:'',
              interfaceId:'',
              agentName:'',
               
            },//加白表单
            interface_id:[],
            numberList:[],
            userType:'system',
            rules:{
              // interfaceId:[
              //       {
              //           required: true,
              //           message: '请选择接口账户',
              //           trigger: 'blur',
              //       },
                  
              //   ],
              //   agentName:[
              //       {
              //       required: true,
              //       message: '请输入代理商名称',
              //       trigger: 'change',
              //       },
              //   ],
              //   company:[
              //   {
              //       required: true,
              //       message: '请输入公司名',
              //       trigger: 'change',
              //       },
              //   ],
                numberList:[
          //       {
          //   required: true,
          //   message: '请填写主叫号码',
          //   trigger: 'blur',
          // },
                ],
              
            }
        }
    },
    
    watch: {

    },
    mounted() {
        this.userType = this.$store.state.userType
        if(this.userType == 'system'){
            this.getChangeValues()
        }else{
            this.getnumberList()
        }
    },
    methods: {
      open(){
        window.open('http://fws.duofangtongxin.com ')

      },

      add(){
this.formlist.push({
  number: "",
  subMerchant: ""
})
      },
        getChangeValues(){
            interfacets.getInterface({}).then((res) => {
                this.interface_id = res.data
            });
        },
        getnumberList(){
            number.getDianHuiNumberlist({}).then((res) => {
                this.numberList = res.data
            });
        },
        unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a) && res.set(a, 1))
    },
        upFormData(formName) {
             // 号码添加/修改--提交

    this.$refs[formName].validate((valid) => {
      if (valid) {
       
          let arrLest = []
          let upArr = []
          arrLest = this.upform.numberList.split(/[(\r\n)\r\n]+/)
          this.unique(arrLest).forEach((item, i) => {
            upArr.push(
              item.trim(),
            
            
            )
          })

          interfacets.activatephone({
            numberList:this.formlist
          }
            
          
          )
              .then((res) => {
            console.log(res.data, 'res.data')
                if (res.code === 200) {
                  this.dataList = res.data
                  // 获取最新数据
                } else {
                  // this.$notify({
                  //               title: '成功',
                  //               message: '验证码发送成功！',
                  //               type: 'error',
                  //           })   

                }
              })
         
       
          // this.upform.number = this.unique(otherArr)
          



      } else {
        console.log('error submit!!')
        return false
      }
    })
  
        },
        getCode(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    ctcc.ctccWhiteSms({
                        ...this.upform,
                        type:0
                    }).then(res => {
                        if(res.code == 200){
                            this.$notify({
                                title: '成功',
                                message: '验证码发送成功！',
                                type: 'success',
                            })
                        }
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.boxall{
  padding: 0 250px 0 30px;
  display: flex;
  justify-content: space-between;
}
    .item-box-other{
        padding: 8px 16px;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #ebebeb;
    }

    .form-item-w-400{
        width: 400px;
    }
    .form-item-w-200{
        width: 200px;
    }
</style>